<template>
  <div class="body_bgi box_1 full bgs_full relative " ref="home_box">
    <div class="full jian_bian_bgc">
      <!-- header -->
      <div class="header_bgi2 w_full h_100 bgs_full ph_20 border_box relative ">
        <div class="fw_bold fs_30 text_right ls_10 absolute left_center top_20 color_fff font_zh cursor" @click="goToPage('/')">
          向北开放重要桥头堡大数据平台
        </div> 

        <!-- 天气、气温 -->
        <div class="w_30_p jian_bian_text_1 text_right absolute top_30 right_10">
          <!-- <AmapWeather /> -->
        </div>
      </div>

      <!-- body -->
      <div class="w_full-60 h_full-110 m_0_auto flex_row_between">
        <!-- 世界地图 -->
        <div class="w_50_p h_full">
          <word-map :heightLightArea="heightLightArea" />
        </div>

        <div class="w_50_p h_full bgc_fbf9f880 border_radius_10 flex_row_between z_999">
          <div class="full flex_column_between p_10">
            <div class="w_full h_30_p">
              <!-- search -->
              <div class="w_full h_60 flex_row_between flex_center">
                <div class="w_60_p flex_row_start flex_center">
                  HS编码： 
                  <div class="w_300">
                    <el-input 
                      v-model="hsCode" clearable placeholder="请输入HS编码" 
                      @keyup.enter.native="russiaQueryHs6()" 
                      @clear="clearHs6Value"
                    >
                      <el-button slot="append" icon="el-icon-search" @click="russiaQueryHs6()"></el-button>
                    </el-input>
                  </div>
                </div>

                <div class="w_40_p flex_row_end">
                  <div 
                    class="w_60 h_30 lh_30 bgc_fff border_radius_5 text_center cursor"
                    :class="{ 'bgc_0874EF color_fff': activeType == '进口' }"
                    @click="clickTypeBtn('进口')"
                  >进口</div>
                  <div 
                    class="w_60 h_30 lh_30 bgc_fff border_radius_5 text_center ml_10 cursor"
                    :class="{ 'bgc_d21e42 color_fff': activeType == '出口' }"
                    @click="clickTypeBtn('出口')"
                  >出口</div>
                </div>
              </div>

              <div class="w_full p_10 fw_bold color_000 ls_1 bgc_fafafac2">
                商品名称： {{ product.description }}
              </div>

              <div class="w_full h_40_p bgc_fafafac2 pl_10">
                <div class="fw_bold">对俄不友好国家（地区）:</div>
                <div class="w_full h_60 scroll_box overflow_y_auto float_left flex_wrap ">
                  <span
                    class="fs_18 cursor mr_10"
                    :class="{color_0874EF: activeCountry1 == item1}"
                    v-for="(item1, index1) in countryList1" :key="index1"
                    @click="clickCountry1(item1)"
                  >{{ item1 }}
                  </span>
                </div>
              </div>

              <div class="w_full h_40_p bgc_fafafac2 pl_10">
                <div class="fw_bold">转口贸易国家（地区）:</div>
                <div class="w_full float_left flex_wrap">
                  <span
                    class=" fs_18 cursor mr_10"
                    :class="{color_d21e42: maoyi_id == item2.id}"
                    v-for="(item2, index2) in countryList2" :key="index2"
                    @click="clickCountry2(item2)"
                  >{{ item2.maoyi }}
                  </span>
                </div>
              </div>
            </div>
            
            <div class="w_full h_60_p pt_5">
              <div class="w_full h_27_p">
                <div class="w_full flex_row_start border_1_s_fff bgc_2366fc" v-if="hsCode">
                  <div class="w_40_p h_26 border_1_s_fff"></div>
                  <div class="w_20_p border_1_s_fff color_fff text_center">2021</div>
                  <div class="w_20_p border_1_s_fff color_fff text_center">2022</div>
                  <div class="w_20_p border_1_s_fff color_fff text_center">增减幅度</div>
                </div>

                <div class="w_full" v-if="activeType == '进口' && hsCode">
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff">俄罗斯自<span class="color_0874EF">{{ activeCountry1 }}</span>进口额</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2021russia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2022russia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">下降{{ activeData.eedRussiaDropRate || '' }}%</div>
                  </div>
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff">
                      <span class="color_0874EF">{{ activeCountry2.maoyi }}</span>
                      自
                      <span class="color_d21e42">{{ activeCountry1 }}</span>
                      进口额
                    </div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2021maoyi }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2022maoyi }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">增长{{ activeData.eedMaoyiRiseRate || '' }}%</div>
                  </div>
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff">俄罗斯自<span class="color_d21e42">{{ activeCountry2.maoyi }}</span>进口额</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2021maoyiRussia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.eed2022maoyiRussia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">增长{{ activeData.eedMaoyiRussiaRiseRate || '' }}%</div>
                  </div>
                </div>

                <div class="w_full" v-if="activeType == '出口' && hsCode">
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff">俄罗斯向<span class="color_0874EF">{{ activeCountry1 }}</span>出口额</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2021russia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2022russia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">下降{{ activeData.iedRussiaDropRate || '' }}%</div>
                  </div>
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff">俄罗斯向<span class="color_d21e42">{{ activeCountry2.maoyi }}</span>出口额</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2021maoyiRussia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2022maoyiRussia }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">增长{{ activeData.iedMaoyiRussiaRiseRate || '' }}%</div>
                  </div>
                  <div class="w_full flex_row_start border_1_s_fff bgc_fafafac2">
                    <div class="w_40_p h_26 border_1_s_fff"><span class="color_d21e42">{{ activeCountry2.maoyi }}</span>向<span class="color_0874EF">{{ activeCountry1 }}</span>出口额</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2021maoyi }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_right pr_10">{{ activeData.ied2022maoyi }}</div>
                    <div class="w_20_p border_1_s_fff color_999 text_center">增长{{ activeData.iedMaoyiRiseRate || '' }}%</div>
                  </div>
                </div>
              </div>
              
              <div class="w_full h_70_p bgc_fafafac2 p_10">
                <data-chart 
                  title="对俄罗斯出口、转口总额"
                  chartType="三柱图" 
                  :isShowTitle="true"
                  :isShowUnit="true"
                  :isShowFilterIcon="false"
                  :isShowFullIcon="true"
                  :chartData="chartData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AmapWeather from '@/components/weather/AmapWeather.vue';

import defMixin from '@/mixins/def';
import componentsMixin from '@/mixins/components';

import { 
  getProductInfo,
  rtiQueryHs6,
  rteQueryHs6,
} from '@/api/base/index.js'

import { Message } from 'element-ui'

export default {
  name: 'Russia',
  components: {
    AmapWeather,
  },
  mixins: [defMixin, componentsMixin],
  data() {
    return {
      hsCode: '870324',

      product: {},

      heightLightArea: [],
      activeType: '进口',

      russiaDataList: [],

      activeCountry1: {},
      countryList1: [],

      activeCountry2: {},
      maoyi_id: null,
      countryList2: [],

      activeData: {},

      chartData: {
        x: [],
        unit: '美元',
        data1: {
          name: '',
          data: []
        },
        data2: {
          name: '',
          data: []
        },
        data3: {
          name: '',
          data: []
        }
      }
    }
  },
  async mounted() {
    this.heightLightArea = ['俄罗斯']

    this.russiaQueryHs6()

    if (this.countryList2.length) {
      this.clickCountry2(this.countryList2[0])
    }
  },
  destroyed() {

  },
  methods: {
    // 获取 俄罗斯 进口
    async rtiQueryHs6() {
      let res = await rtiQueryHs6(this.hsCode)
      try {
        if (res.code == 1000) {
          this.russiaDataList = res.data
          // debugger

          let cou_country_list = []
          res.data.map(item => cou_country_list.push(item.cou))

          this.countryList1 = [...new Set(cou_country_list)]
          this.activeCountry1 = this.countryList1[0]

          let cou = res.data[0].cou
          let countryList2 = res.data.filter(item => item.cou == cou)
          this.countryList2 = countryList2

          if (countryList2.length) {
            this.clickCountry2(countryList2[0])
          }
        }
      } catch(err) {
        console.log('获取俄罗斯进口总额: ', err);
      }
    },

    // 获取 俄罗斯 出口
    async rteQueryHs6() {
      let res = await rteQueryHs6(this.hsCode)
      try {
        if (res.code == 1000) {
          this.russiaDataList = res.data

          let cou_country_list = []
          res.data.map(item => cou_country_list.push(item.cou))

          this.countryList1 = [...new Set(cou_country_list)]
          this.activeCountry1 = this.countryList1[0]

          let cou = res.data[0].cou
          let countryList2 = res.data.filter(item => item.cou == cou)
          this.countryList2 = countryList2

          if (countryList2.length) {
            this.clickCountry2(countryList2[0])
          }
        }
      } catch(err) {
        console.log('获取俄罗斯出口总额: ', err);
      }
    },

    russiaQueryHs6() {
      this.getProductInfo(this.hsCode)

      if (this.hsCode) {
        if (this.activeType == '进口') {
          this.rtiQueryHs6()
        } else {
          this.rteQueryHs6()
        }
      } else {
        Message({
          message: '请输入 HS 编码',
          type: 'error',
          duration: 1 * 1000
        })
      }
    },

    // 获取 商品 信息
    async getProductInfo(hsCode) {
      this.product = {}
      let res = await getProductInfo(hsCode)
      try {
        if (res.code == 1000) {
          this.product = res.data
        }
      } catch(err) {
        console.log('获取商品信息失败', err);
      }
    },

    clickTypeBtn(val) {
      this.activeType = val

      if (this.hsCode) {
        if (val == '进口') {
          this.rtiQueryHs6()
        } else {
          this.rteQueryHs6()
        }
      } else {
        Message({
          message: '请输入 HS 编码',
          type: 'error',
          duration: 1 * 1000
        })
      }
    },

    clearHs6Value() {
      this.hsCode = ''

      this.activeCountry1 = ''
      this.activeCountry2 = {}
      this.countryList1 = []
      this.countryList2 = []

      this.activeData = {}

      this.chartData = {
        x: [],
        unit: '美元',
        data1: {
          name: '',
          data: []
        },
        data2: {
          name: '',
          data: []
        },
        data3: {
          name: '',
          data: []
        }
      }
    },

    clickCountry1(item) {
      this.activeCountry1 = item

      this.countryList2 = this.russiaDataList.filter(data => data.cou == item)

      this.clickCountry2(this.countryList2[0])
    },

    clickCountry2(item) {
      this.maoyi_id = item.id
      this.activeCountry2 = item

      let cou = this.activeCountry1
      let maoyi = item.maoyi
      
      this.countryList2.filter(item => {
        if(item.cou == cou && item.maoyi == maoyi) {
          this.activeData = item
        }
      })

      // 高亮 飞线
      this.heightLightArea = ['俄罗斯', this.activeCountry1, this.activeCountry2.maoyi]

      if (this.activeType == '进口') {
        this.chartData = {
          x: [2021, 2022].reverse(),
          unit: '美元',
          data1: {
            name: '向俄罗斯出口额',
            data: [this.activeData.eed2021russia, this.activeData.eed2022russia].reverse()
          },
          data2: {
            name: `向${this.activeCountry2.maoyi}出口额`,
            data: [this.activeData.eed2021maoyi, this.activeData.eed2022maoyi].reverse()
          },
          data3: {
            name: `${this.activeCountry2.maoyi}向俄罗斯出口额`,
            data: [this.activeData.eed2021maoyiRussia, this.activeData.eed2022maoyiRussia].reverse()
          }
        }
      } else {
        this.chartData = {
          x: [2021, 2022].reverse(),
          unit: '美元',
          data1: {
            name: '向俄罗斯进口额',
            data: [this.activeData.ied2021russia, this.activeData.ied2022russia].reverse()
          },
          data2: {
            name: `向${this.activeCountry2.maoyi}进口额`,
            data: [this.activeData.ied2021maoyi, this.activeData.ied2022maoyi].reverse()
          },
          data3: {
            name: `${this.activeCountry2.maoyi}向俄罗斯进口额`,
            data: [this.activeData.ied2021maoyiRussia, this.activeData.ied2022maoyiRussia].reverse()
          }
        }
      }
      
      this.chartData = {...this.chartData}
    },

    // 跳转页面
    goToPage(path) {
      this.$router.push(path)
    }

  }
}
</script>

